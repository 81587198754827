import { COLLAPSE_SIDEBAR, HIDE_COLUMN, INIT_STORE, REORDER_COLUMN, RESIZE_COLUMN } from "./AppVariables";

const initialState = {
  tables: [],
  collapsedSider: false,
};

const AppReducer = (state: any, action: any) => {
  switch (action.type){
    case INIT_STORE: {
      return action.value;
    }
    case RESIZE_COLUMN: {
      const table = state.tables?.find((table: any) => table.id === action.tableId);

      if (table) {
        return {
          ...state,
          tables: state.tables.map((table: any) => {
            if (table.id === action.tableId) {
              return {
                ...table,
                columnWidths: {
                  ...table.columnWidths,
                  ...action.columnWidths
                }
              };
            } else {
              return table;
            }
          })
        };
      } else {
        return {
          ...state,
          tables: [
            ...state.tables,
            {
              id: action.tableId,
              columnWidths: action.columnWidths
            }
          ]
        };
      }
    }
    case HIDE_COLUMN: {
      const table = state.tables?.find((table: any) => table.id === action.tableId);

      if (table) {
        return {
          ...state,
          tables: state.tables.map((table: any) => {
            if (table.id === action.tableId) {
              return {
                ...table,
                hiddenColumns: action.hiddenColumns
              };
            } else {
              return table;
            }
          })
        };
      } else {
        return {
          ...state,
          tables: [
            ...state.tables,
            {
              id: action.tableId,
              hiddenColumns: action.hiddenColumns
            }
          ]
        };
      }
    }
    case REORDER_COLUMN: {
      const table = state.tables?.find((table: any) => table.id === action.tableId);

      if (table) {
        return {
          ...state,
          tables: state.tables.map((table: any) => {
            if (table.id === action.tableId) {
              return {
                ...table,
                columnOrder: action.columnOrder
              };
            } else {
              return table;
            }
          })
        };
      } else {
        return {
          ...state,
          tables: [
            ...state.tables,
            {
              id: action.tableId,
              columnOrder: action.columnOrder
            }
          ]
        };
      }
    }
    case COLLAPSE_SIDEBAR: {
      return {
        ...state,
        collapsedSider: action.collapsed
      };
    }
    default:
      return state;
  }
};

export { AppReducer, initialState };
