import { FC } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import { ConfigProvider } from 'antd';
import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';

import { useApollo } from '~/apollo/client';
import { AppProvider } from '~/contexts/AppContext';

import '../styles/globals.css';

const MyApp: FC<AppProps> = ({
  Component,
  pageProps: { session, ...pageProps },
  router
}) => {
  const client = useApollo(pageProps);

  return (
    <AppProvider>
      <ApolloProvider
        client={client}
      >
        <SessionProvider session={session}>
          <ConfigProvider locale={locale}>
            <Head>
              <title>OASIS CRM</title>
              <link rel="icon" href="/favicon.ico" />
              <meta property="og:url" content={process.env.DOMAIN + router.asPath} />
              <meta property="og:locale" content="ru_RU" />
            </Head>
            <Component {...pageProps} />
            <Script src="https://apis.google.com/js/api.js" />
          </ConfigProvider>
        </SessionProvider>
      </ApolloProvider>
    </AppProvider>
  );
};

export default MyApp;
