import { useMemo } from 'react';
import merge from 'deepmerge';
import { AppProps } from 'next/app';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject, concat, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition, offsetLimitPagination } from '@apollo/client/utilities';
import isEqual from 'lodash/isEqual';
// import fetch from 'isomorphic-unfetch';

export const APOLLO_STATE_PROPERTY_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

// let accessToken: string | null = null;

// const requestAccessToken = async () => {
//   if (accessToken) return;

//   const res = await fetch(`${process.env.DOMAIN}/api/session`);

//   console.log(res);

//   if (res.ok) {
//     const json = await res.json();

//     if (json.token) {
//       accessToken = json.token;
//     }
//   }
// };

function createApolloClient() {
  const ssrMode = typeof window === 'undefined';
  const isDev = true; // process.env.NODE_ENV === 'development';

  const httpLink = new HttpLink({
    uri: `${process.env.GRAPHQL_URL}/v1/graphql`,
    credentials: 'include',
  });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: any) => ({ headers: {
      ...headers,
      'x-hasura-admin-secret': `${process.env.HASURA_SECRET}`
    }}));

    return forward(operation);
  });

  const wsLink = process.browser ? new WebSocketLink({
    uri: `${process.env.GRAPHQL_WS_URL}/v1/graphql`,
    options: {
      timeout: 30000,
      reconnect: true,
      connectionParams: () => {
        // console.log(' ----- params ----- ');
        // console.log(params);
        // console.log(' ----- params ----- ');
        // await requestAccessToken();
        if (isDev) {
          return {
            headers: {
              'x-hasura-admin-secret': `${process.env.HASURA_SECRET}`
            }
          };
        }
        // else {
        //   return {
        //     headers: {
        //       authorization: accessToken ? `Bearer ${accessToken}` : '',
        //     }
        //   };
        // }
      }
    }
  }) : undefined;

  const splitLink = (process.browser && wsLink) ? split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  ) : httpLink;

  return new ApolloClient({
    ssrMode,
    link: concat(authLink, splitLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            t_message: offsetLimitPagination()
          }
        }
      }
    }),
  });
}

export function initializeApollo(initialState?: NormalizedCacheObject): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from
    // getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: AppProps['pageProps'],
): AppProps['pageProps'] {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROPERTY_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: AppProps['pageProps']): ApolloClient<NormalizedCacheObject> {
  const state = pageProps[APOLLO_STATE_PROPERTY_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
}
