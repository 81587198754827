import { createContext, useEffect, useContext, useMemo, useReducer, FC } from 'react';

import { AppReducer, initialState } from './AppReducer';
import { COLLAPSE_SIDEBAR, HIDE_COLUMN, ID_APP_CONTEXT, INIT_STORE, REORDER_COLUMN, RESIZE_COLUMN } from "./AppVariables";

interface ColumnProps {
  [key: string]: number
}

interface TableProps {
  id: string,
  columnWidths?: ColumnProps;
  hiddenColumns?: string[];
  columnOrder?: string[];
}

interface AppContextProps {
  state: {
    tables: TableProps[];
    collapsedSider?: boolean;
  };
  resizeColumn: (tableId: string, columnWidths: object) => void;
  hideColumn: (tableId: string, hiddenColumns: string[]) => void;
  reorderColumn: (tableId: string, columnOrder: string[]) => void;
  collapseSider: (collapsed: boolean) => void;
}

const AppContext = createContext<AppContextProps | null>(null);

const AppProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const resizeColumn = (tableId: string, columnWidths: object) =>
    dispatch({ type: RESIZE_COLUMN, tableId, columnWidths });

  const hideColumn = (tableId: string, hiddenColumns: string[]) =>
    dispatch({ type: HIDE_COLUMN, tableId, hiddenColumns });

  const reorderColumn = (tableId: string, columnOrder: string[]) =>
    dispatch({ type: REORDER_COLUMN, tableId, columnOrder });

  const collapseSider = (collapsed: boolean) =>
    dispatch({ type: COLLAPSE_SIDEBAR, collapsed });

  const contextValue = useMemo(() => {
    return { state, resizeColumn, hideColumn, reorderColumn, collapseSider };
  }, [state, dispatch, resizeColumn, hideColumn, reorderColumn, collapseSider]);

  useEffect(() => {
    const ctx = localStorage.getItem(ID_APP_CONTEXT);
    if (ctx && JSON.parse(ctx)) {
      dispatch({
        type: INIT_STORE,
        value: JSON.parse(ctx),
      });
    }
 }, []);

 useEffect(() => {
    if (state !== initialState) {
      localStorage.setItem(ID_APP_CONTEXT, JSON.stringify(state));
    }
 }, [state]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be inside an FC component');
  }

  return context;
};

export { AppProvider, AppContext, useAppContext };
